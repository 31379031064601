import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function CalendarIcon() {
  return (
    <SvgIcon>
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.8637 3.81818H19.7728C20.7728 3.81818 21.591 4.63636 21.591 5.63636V20.1818C21.591 21.1818 20.7728 22 19.7728 22H5.22736C4.22736 22 3.40918 21.1818 3.40918 20.1818V5.63636C3.40918 4.63636 4.22736 3.81818 5.22736 3.81818H6.13645V2.90909C6.13645 2.40909 6.54554 2 7.04554 2C7.54554 2 7.95463 2.40909 7.95463 2.90909V3.81818H17.0455V2.90909C17.0455 2.40909 17.4546 2 17.9546 2C18.4546 2 18.8637 2.40909 18.8637 2.90909V3.81818ZM6.13645 20.1818H18.8637C19.3637 20.1818 19.7728 19.7727 19.7728 19.2727V8.36364H5.22736V19.2727C5.22736 19.7727 5.63645 20.1818 6.13645 20.1818Z" fill="#94A3B8"/>
      </svg>
    </SvgIcon>
  );
}