import React from "react";
// Customizable Area Start
import { NavigateNext } from '@mui/icons-material';
import { Breadcrumbs, Link, Typography, Tabs, Tab, Box, IconButton, Snackbar } from '@mui/material';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {CustomerDetails} from "../../../components/src/CustomerDetails.web"
import {CustomerProperties} from "./CustomerProperties.web"
import {CustomerTenants} from "./CustomerTenants.web"
import {CustomerMeters} from "./CustomerMeters.web"
import { createPortal } from 'react-dom';

// Customizable Area End

import CustomersPageController, {
  Props,
  configJSON,
} from "./CustomersPageController";
// Customizable Area Start
import { CustomerReports } from "./CustomerReports.web";
import { CustomerCreateEditReport } from "./CustomerCreateEditReport.web";
import { waterIcon } from './assets';
import FlashOnRoundedIcon from '@mui/icons-material/FlashOnRounded';
import { CustomerReportInvoice } from "./CustomerReportInvoice.web";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

// Customizable Area End
export default class CustomersPageDetails extends CustomersPageController {
  constructor(props: Props) {
    super(props);
  }
  // Customizable Area Start

  getReportTypeChip(reportType: 'Water' | 'Electric') {
    if (reportType === 'Water') {
      return (
        <Box sx={webStyle.reportTypeChipWrapper('#DBEAFE')}>
          <Typography sx={webStyle.reportTypeChipText('#2563EB')}>{reportType}</Typography>
          <img src={waterIcon} style={webStyle.reportTypeChipWaterIcon} />
        </Box>
      )
    }

    return (
      <Box sx={webStyle.reportTypeChipWrapper('#FEF3C7')}>
        <Typography sx={webStyle.reportTypeChipText('#D97706')}>{reportType}</Typography>
        <FlashOnRoundedIcon sx={webStyle.reportTypeChipElectricityIcon} />
      </Box>
    )
  }

  getTitle() {
    if (this.state.selectedProperty && this.state.selectedTenant) {
      return <Typography variant="h5" sx={webStyle.companyNameTitle}>Meters</Typography>;
    }

    if (this.state.selectedProperty) {
      return <Typography variant="h5" sx={webStyle.companyNameTitle}>Tenants</Typography>;
    }

    if (this.state.isAddReportOpen && this.state.selectedReport) {
      return (
        <Box sx={webStyle.reportTitleWrapper}>
          <Typography variant="h5" sx={webStyle.companyNameTitle}>Add report</Typography>
          {this.getReportTypeChip(this.state.selectedReport.type)}
        </Box>
      );
    }

    if (this.state.selectedReport) {
      return (
        <Box sx={webStyle.reportTitleWrapper}>
          <Typography variant="h5" sx={webStyle.companyNameTitle}>{`${this.state.isInvoiceOpen ? 'Invoice' : 'Report'} ${this.state.selectedReport.id}`}</Typography>
          {this.getReportTypeChip(this.state.selectedReport.type)}
        </Box>
      );
    }

    return <Typography variant="h5" sx={webStyle.companyNameTitle}>{this.state.companyDetail.companyName}</Typography>;
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.container}>
     <Box marginBottom={"24px"}>
     <Breadcrumbs 
        aria-label="breadcrumb" 
        sx={webStyle.breadcrumb} 
        separator={<NavigateNext fontSize="small" sx={{ color: '#1A7BA4' }} />}
      >
        <Link sx={webStyle.breadcrumbsText(true)} href="CustomersPage">
          Customers
        </Link>
        <Typography
          sx={webStyle.breadcrumbsText(this.state.tabIndex >= 1)}
          onClick={() => {
            if (this.state.tabIndex === 0) {
              return;
            }

            this.setState({ selectedReport: null, selectedProperty: null, selectedTenant: null, isAddReportOpen: false, isInvoiceOpen: false, tabIndex: 0 })
          }}
          data-test-id="companyNameBreadcrumb"
        >
          {this.state.companyDetail.companyName}
        </Typography>
        {this.state.tabIndex === 1 && (
          <Typography
            sx={webStyle.breadcrumbsText(!!this.state.selectedProperty || !!this.state.selectedTenant)}
            onClick={() => {
              if (!this.state.selectedProperty && !this.state.selectedTenant) {
                return;
              }

              this.setState({ selectedProperty: null, selectedTenant: null })
            }}
            data-test-id="propertiesBreadcrumb"
          >
            Properties
          </Typography>
        )}
        {this.state.tabIndex === 1 && this.state.selectedProperty && (
          <Typography
            sx={webStyle.breadcrumbsText(!!this.state.selectedTenant)}
            onClick={() => {
              if (!this.state.selectedProperty) {
                return;
              }

              this.setState({ selectedTenant: null });
            }}
            data-test-id="tenantsBreadcrumb"
          >
            Tenants
          </Typography>
        )}
         {this.state.tabIndex === 1 && this.state.selectedTenant && (
            <Typography sx={webStyle.breadcrumbsText()}>Meters</Typography>
          )}
        {this.state.tabIndex === 2 && (
          <Typography
            sx={webStyle.breadcrumbsText(!!this.state.selectedReport)}
            onClick={() => {
              if (!this.state.selectedReport) {
                return;
              }

              this.setState({ selectedReport: null, isAddReportOpen: false, isInvoiceOpen: false });
            }}
            data-test-id="reportsBreadcrumb"
          >
            Reports
          </Typography>
        )}
        {this.state.tabIndex === 2 && this.state.isAddReportOpen && (
          <Typography sx={webStyle.breadcrumbsText()}>Add report</Typography>
        )}
        {this.state.tabIndex === 2 && !this.state.isAddReportOpen && !!this.state.selectedReport && !this.state.isInvoiceOpen && (
          <Typography sx={webStyle.breadcrumbsText()}>{`Report ${this.state.selectedReport.id}`}</Typography>
        )}
        {this.state.tabIndex === 2 && this.state.isInvoiceOpen && !!this.state.selectedReport && (
          <Typography sx={webStyle.breadcrumbsText()}>{`Invoice ${this.state.selectedReport.id}`}</Typography>
        )}
      </Breadcrumbs>
     </Box>

     <Box sx={webStyle.header}>
  <Box sx={webStyle.details}>
    <Box sx={{ display: "flex", flexDirection: 'row', alignItems: "center", justifyContent: "flex-start" }}>
      <IconButton onClick={this.goBack}>
        <ArrowBackIcon />
      </IconButton>
      {this.getTitle()}
    </Box>
    
        {!this.state.selectedProperty && !this.state.selectedReport && (
          <Box sx={{ display: "flex", flexDirection: "row", justifyContent: 'space-between', width: "95%", marginLeft: "7px", color: "#64748B" }}>
            <Box sx={webStyle.contactInfo}>
              <EmailOutlinedIcon sx={{ marginBottom: "3px" }} />
              <Typography sx={webStyle.contactInfoText}>{this.state.companyDetail.email}</Typography>
            </Box>
            <Box sx={webStyle.contactInfo}>
              <PhoneOutlinedIcon />
              <Typography sx={webStyle.contactInfoText}>{this.state.companyDetail.phone}</Typography>
            </Box>
            <Box sx={webStyle.contactInfo}>
              <LocationOnOutlinedIcon />
              <Typography sx={webStyle.contactInfoText}>{this.state.companyDetail.mailingAdress}</Typography>
            </Box>
          </Box>
        )}
      </Box>
    </Box>

      {!this.state.selectedProperty && !this.state.selectedReport && (
        <Tabs value={this.state.tabIndex} onChange={this.handleTabChange} aria-label="company details tabs" sx={webStyle.tabs}>
          <Tab 
            label="Company details" 
            sx={webStyle.tabButton(this.state.tabIndex === 0)}
          />
          <Tab 
            label="Properties" 
            sx={webStyle.tabButton(this.state.tabIndex === 1)}
          />
          <Tab 
            label="Reports" 
            sx={webStyle.tabButton(this.state.tabIndex === 2)}
          />
        </Tabs>
      )}
      

      <Box role="tabpanel" hidden={this.state.tabIndex !== 0}>
        {this.state.tabIndex === 0 && (
          <Box data-test-id="customerDetailsWrapper">
            <CustomerDetails
                handleCompanyChange ={this.handleCompanyChange}
                companyDetails = {this.state.companyDetail}
                apiErrors={this.state.companyDetailsApiErrors}
                isEditing={this.state.isCompanyDetailsEditing}
                setIsEditing={(value: boolean) => this.setState({ isCompanyDetailsEditing: value, companyDetailsApiErrors: null })}
            />
          </Box>
        )}
      </Box>
      <Box role="tabpanel" hidden={this.state.tabIndex !== 1}>
      {this.state.selectedProperty && this.state.selectedTenant ? (
    <CustomerMeters 
      meterList={this.state.meterList}
      getMeterList={() => this.state.selectedTenant?.id !== undefined && this.state.selectedTenant?.id !== null && this.getTenantMeters(this.state.selectedTenant?.id)}
      meterListLoading={this.state.tenantMetersLoading}
      addEditMeterDialogProps={this.state.addEditTenantMeterDialogProps}
      deleteTenantMeterIdDialog={this.state.deleteTenantMeterIdDialog}
      setAddEditMeterDialogProps={this.setAddEditTenantMeterDialogProps}
      setDeleteTenantMeterIdDialog={this.setDeleteTenantMeterIdDialog}
      addEditMeter={this.addEditTenantMeter}
      deleteMeter={this.deleteTenantMeter}
    />
  ) : this.state.selectedProperty ? (
    <CustomerTenants
      tenantList={this.state.tenantList}
      addMeter={this.handleAddMeterClick}
      getTenantList={() => this.getPropertyTenants(this.state.selectedProperty?.id || '')}
      tenantsLoading={this.state.tenantsLoading}
      deleteTenantDialogId={this.state.deleteTenantDialogId}
      setDeleteTenantDialogId={this.setDeleteTenantDialogId}
      addEditTenantsDialogProps={this.state.addEditTenantsDialogProps}
      setAddEditTenantsDialogProps={this.setAddEditTenantsDialogProps}
      addEditTenant={this.addEditPropertyTenant}
      deleteTenant={this.deletePropertyTenant}
    />
  ) : (
    <CustomerProperties
      companyId={this.state.companyDetail.id}
      getPropertiesList={this.getCompanyProperties}
      propertiesList={this.state.propertiesList}
      propertiesLoading={this.state.propertiesLoading}
      addEditCompanyProperty={this.addEditCompanyProperty}
      addEditProperyDialog={this.state.addEditPropertyDialog}
      setAddEditPropertyDialog={this.setAddEditPropertyDialog}
      deleteCompanyProperty={this.deleteCompanyProperty}
      deleteCompanyPropertyIdDialog={this.state.deleteCompanyPropertyIdDialog}
      setDeleteCompanyPropertyIdDialog={this.setDeleteCompanyPropertyIdDialog}
      addTenant={this.addTenant}
      assignBookNumberToProperty={this.assignBookNumberToProperty}
    />
  )}
      </Box>
      <Box role="tabpanel" hidden={this.state.tabIndex !== 2}>
        {this.state.selectedReport && this.state.isInvoiceOpen && (
          <CustomerReportInvoice report={this.state.selectedReport} />
        )}
        {this.state.selectedReport && !this.state.isInvoiceOpen && (
          <CustomerCreateEditReport
            isAdd={this.state.isAddReportOpen}
            report={this.state.selectedReport}
            generateReport={(report) => this.generateReport(report)}
            onCancel={() => this.onCancelReportCreation()}
            onAddSubmit={() => this.onSubmitNewReport()}
          />
        )}
        {!this.state.selectedReport && this.state.tabIndex === 2 && (
          <CustomerReports
            properties={this.state.propertiesList}
            setSelectedReport={(report) => this.setSelectedReport(report)}
            setAddReport={() => this.setAddReport()}
            openInvoice={(report) => this.onOpenInvoice(report)}
            reportsList={this.state.reportsList}
            reportsListLoading={this.state.reportsLoading}
            getReportsList={this.getReportsListRequest}
            setReportsList={(value) => this.setState({ reportsList: value })}
            generateReport={this.generateReportRequest}
          />
        )}
      </Box>
      {createPortal(
        <Snackbar
          open={this.state.snackbarProps.isOpen}
          message={
            <Box sx={webStyle.snackbarContent}>
              {this.state.snackbarProps?.type === 'Error' ? (
                <CancelRoundedIcon
                  sx={{
                    ...webStyle.snackbarIcon,
                    ...webStyle.snackbarWarningIcon,
                  }}
                />
              ) : (
                <CheckCircleRoundedIcon
                  sx={{
                    ...webStyle.snackbarIcon,
                    ...webStyle.snackbarSuccessIcon,
                  }}
                />
              )}
              <Typography sx={webStyle.snackbarText}>
                {this.state.snackbarProps?.message}
              </Typography>
            </Box>
          }
          autoHideDuration={3500}
          onClose={() => this.setSnackbarProps({ ...this.state.snackbarProps, isOpen: false })}
          sx={webStyle.snackbar}
        />,
        document.body,
      )}
    </Box>
      // Customizable Area Start
    );
  }
}
// Customizable Area Start


const webStyle = {
  container: {
    padding: '40px 50px',
    backgroundColor:"#F8FAFC",
    fontFamily: "'Inter', sans-serif",
    height: '100%',
  },
  breadcrumb: {
    marginBottom: '8px',
    fontFamily: "'Inter', sans-serif",
  },
  breadcrumbCompanyName: {
    fontFamily: "'Inter', sans-serif",
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontFamily: "'Inter', sans-serif",
    marginLeft:"-7px"
  },
  details: {
    display: 'flex',
    flexDirection:'column',
    gap: '16px',
    width:"100%",
    fontFamily: "'Inter', sans-serif",
  },
  contactInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    fontSize:"16px",
    fontFamily: "'Inter', sans-serif",
    flex: 1,
    wordBreak: 'break-word',
  },
  contactInfoText: {
    fontFamily: "'Inter', sans-serif",
  },
  tabButton: (isActive: boolean) => ({
    fontWeight: isActive ? 700 : 400,
    color: isActive ? '#1A7BA4' : '#64748B',
    textTransform: 'none',
    padding: '8px 12px',
    fontFamily: "'Inter', sans-serif",
    fontSize: '16px',
    marginBottom: isActive ? "0px" : "2px",
  }),
  tabs: {
    borderBottom: '1px solid #e0e0e0',
    marginTop: '16px',
    fontSize:"16px",
    fontFamily: "'Inter', sans-serif",
    '& .Mui-selected': {
      color: '#1A7BA4 !important',
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#1A7BA4 !important',
    }
  },
  companyNameTitle: {
    color: 'rgba(15, 23, 42, 1)',
    fontFamily: "'Inter', sans-serif",
    fontWeight: 600,
    wordBreak: 'break-word'
  },
  breadcrumbsText: (isActive?: boolean) => ({
    color: isActive ? '#1A7BA4' : '#0F172A',
    fontWeight: isActive ? 400 : 500,
    textDecoration: isActive ? 'underline' : 'none',
    cursor: isActive ? 'pointer' : undefined,
    fontFamily: '"Inter", sans-serif',
    wordBreak: 'break-word' as any,
    '&:hover': {
      textDecoration: 'none',
    }
  }),
  reportTypeChipWrapper: (backgroundColor: string) => ({
    display: 'flex',
    flexDirection: 'row',
    gap: '2px',
    alignItems: 'center',
    padding: '2px 8px',
    textTransform: 'uppercase',
    borderRadius: '40px',
    backgroundColor,
  }),
  reportTypeChipText: (color: string) => ({
    fontSize: '12px',
    fontWeight: 700,
    fontFamily: '"Inter", sans-serif',
    color,
  }),
  reportTypeChipElectricityIcon: {
    color: '#F59E0B',
    width: '16px',
    height: '16px',
  },
  reportTypeChipWaterIcon: {
    height: '16px',
    width: '16px',
  },
  reportTitleWrapper: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  snackbar: {
    left: "50% !important",
    transform: "translateX(-50%)",
    fontFamily: "'Inter', sans-serif",
    "& .MuiPaper-root": {
      backgroundColor: "#FFFFFF",
      fontFamily: "'Inter', sans-serif !important",
      padding: "8px 16px 8px 8px",
      boxShadow: "0px 6px 15px -3px #00000026",
      borderRadius: "8px",
      minWidth: "auto",
      "& .MuiSnackbarContent-message": {
        padding: 0,
      },
    },
  },
  snackbarContent: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  snackbarIcon: {
    width: "26px",
    height: "26px",
  },
  snackbarWarningIcon: {
    "> path": {
      fill: "#F87171",
    },
  },
  snackbarSuccessIcon: {
    "> path": {
      fill: "#34D399",
    },
  },
  snackbarText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
  },
};
// Customizable Area End
