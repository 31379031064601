import React, { ChangeEvent } from "react";
// Customizable Area Start
import { Typography, Box, Select, MenuItem, Modal, Tooltip, Autocomplete, CircularProgress, TextField, Button } from "@mui/material";
import { Formik } from "formik";
import { styled } from '@mui/material/styles';
import Dropzone from 'react-dropzone';
import moment from "moment";
import * as Yup from "yup";

import KeyboardArrowLeftRoundedIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";

import {
  companyNameIcon,
  managerIcon,
  contactInfoIcon,
  rightArrowIcon,
  leftArrowIcon,
  closeIcon,
  successIcon,
  uploadIcon,
  addIcon,
  removeIcon,
  errorIcon,
  backArrowIcon,
} from "./assets";
// Customizable Area End

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

import AddReadingPageController, { configJSON, Props } from "./AddReadingPageController";
// Customizable Area Start
// Customizable Area End

const acceprtFileType = ["image/png", "image/jpeg"]
export default class AddReadingPage extends AddReadingPageController {
  constructor(props: Props) {
    super(props);
  }
  
  createReadingSchema = () => {
    return Yup.object().shape({
      currReading: Yup.string()
        .when("note", {
          is: (note) => !note || note.length === 0,
          then: Yup.string()
            .required("Current reading is required")
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(5, "Must be 5 digits minimum")
            .max(9, "Must be 9 digits maximum"),
          otherwise: Yup.string()
        }),
      note: Yup.string()
        .when("currReading", {
          is: (currReading) => !currReading || currReading.length === 0,
          then: Yup.string().required("Meter read/ing note is required"),
          otherwise: Yup.string(),
        }),
    }, [[ "currReading", "note"]]);
  };

  selectProperty = () => (
    <Box sx={webStyle.signUpFormContainer}>
      <Box sx={webStyle.addReadingTitle}>
        <Typography sx={webStyle.formTitle}>{configJSON.step1TitleText}</Typography>
        <Typography sx={webStyle.formSubTitle}>
          {configJSON.step1SubTitleText}
        </Typography>
      </Box>
      <Formik
        initialValues={{
          bookingId: -1,
          property: -1,
          meterNumber: -1,
        }}
        onSubmit={() => {}}
        data-test-id="AddRadingStepOne"
      >
        {({ setFieldValue, values }) => (
          <form>
            <Box sx={webStyle.formContainer}>
              <Box sx={webStyle.fieldControl}>
                <Typography sx={webStyle.inputLabel}>
                  {configJSON.bookFieldLabel}
                </Typography>
                <Box sx={webStyle.bookingField}>
                  <Select
                    value={values?.bookingId}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    data-test-id="bookingId"
                    sx={{ ...webStyle.selectInput(values?.bookingId === -1), width: "50%" }}
                    onChange={(event) => {
                      setFieldValue("bookingId", event.target.value);
                      setFieldValue("property", -1);
                      this.setSelectedProperties(
                        Number(event.target.value)
                      );
                    }}
                  >
                    {values?.bookingId === -1 && (
                      <MenuItem
                        value={-1}
                        sx={{ ...webStyle.selectItem(), display: "none" }}
                      >
                        {configJSON.bookFieldPlaceholder}
                      </MenuItem>
                    )}
                    {new Array(10).fill(0).map((_, index) => (
                      <MenuItem 
                        key={index}
                        value={index + 1} 
                        sx={webStyle.selectItem(values.bookingId === (index + 1))}
                      >
                          {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                  <Box sx={webStyle.removeBookingContaineer}>
                    {values?.bookingId > 0 && (
                      <Box sx={webStyle.removeBookingContainer}>
                        <Box 
                          sx={webStyle.removeBookingButton}
                          data-test-id="removeInfo"
                          onClick={() => {
                            setFieldValue("bookingId", -1);
                            setFieldValue("property", -1);
                            this.setState({ selectedReading: null, selectedProperty: null, properies:[] });
                          }}
                        >
                          <img 
                            src={closeIcon}
                            style={webStyle.reemoveBookingIcon} 
                          />
                          <Typography sx={webStyle.removeBookingText}>{configJSON.clearText}</Typography>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
              <Box sx={webStyle.fieldControl}>
                <Typography sx={webStyle.inputLabel}>
                  {configJSON.propertyFieldLabel}
                </Typography>
                <Autocomplete
                  disablePortal
                  value={this.state.properies.find(({ id }) => id === values.property)?.property_name || ""}
                  options={this.state.properies.map(({ property_name }) => property_name)}
                  onChange={(_, newValue) => {
                    const property = this.state.properies.find(({ property_name }) => property_name === newValue)
                    setFieldValue("property", property?.id);
                    this.setSelectedReading(Number(property?.id));
                  }}
                  sx={webStyle.autocompleteField()}
                  data-test-id="property"
                  renderInput={(params) => (
                    <TextField {...params}  placeholder={configJSON.propertyFieldPlaceholder} />
                  )}
                />
              </Box>
              <Box sx={webStyle.fieldControl}>
                <Box sx={webStyle.informationBlock}>
                  <Box sx={webStyle.informationFieldBlock}>
                    <Box sx={webStyle.informationTitleContainer}>
                      <img
                        src={companyNameIcon}
                        style={webStyle.infoIcon}
                      />
                      <Typography sx={webStyle.informationTitle}>
                        {configJSON.companyNameText}
                      </Typography>
                    </Box>
                    <Typography sx={webStyle.informationField}>
                      {this.state.selectedReading?.company_name || "-"}
                    </Typography>
                  </Box>
                  <Box sx={webStyle.informationFieldBlock}>
                    <Box sx={webStyle.informationTitleContainer}>
                      <img
                        src={contactInfoIcon}
                        style={webStyle.infoIcon}
                      />
                      <Typography sx={webStyle.informationTitle}>
                        {configJSON.contactInfoText}
                      </Typography>
                    </Box>
                    {(this.state.selectedReading?.customer 
                      || this.state.selectedReading?.phone_number 
                      || this.state.selectedReading?.office_number
                    ) ? (
                      <>
                        <Typography sx={webStyle.informationField}>
                          {this.state.selectedReading?.customer}
                        </Typography>
                        <Typography sx={webStyle.informationField}>
                          {this.state.selectedReading?.phone_number}
                        </Typography>
                        <Typography sx={webStyle.informationField}>
                          {this.state.selectedReading?.office_number}
                        </Typography>
                      </>
                    ) : "-"}
                  </Box>
                  <Box sx={webStyle.informationFieldBlock}>
                    <Box sx={webStyle.informationTitleContainer}>
                      <img
                        src={managerIcon}
                        style={webStyle.infoIcon}
                      />
                      <Typography sx={webStyle.informationTitle}>
                        {configJSON.managerText}
                      </Typography>
                    </Box>
                    {(this.state.selectedReading?.building_manager || this.state.selectedReading?.super_number) ? (
                      <>
                        <Typography sx={webStyle.informationField}>
                          {this.state.selectedReading?.building_manager}
                        </Typography>
                        <Typography sx={webStyle.informationField}>
                          {this.state.selectedReading?.super_number}
                        </Typography>
                      </>
                    ) : "-"}
                  </Box>
                </Box>
              </Box>
              <Box sx={webStyle.fieldControlFirstStepGroup}>
                <button
                  style={webStyle.submitButton(!this.state.selectedProperty)}
                  data-test-id="nextBtn"
                  type="button"
                  onClick={() => {
                    this.state.selectedProperty && this.setState({ step: 2 });
                  }}
                >
                  <Typography sx={webStyle.buttonText}>{configJSON.nextButtonText}</Typography>
                  <img src={rightArrowIcon} style={webStyle.infoIcon} />
                </button>
              </Box>
            </Box>
          </form>
        )}
      </Formik>
    </Box>
  )

  selectMeter = () => (
    <Box sx={webStyle.meterListTableContainer}>
      {this.state.premiseMeterList.length ? (
        <Modal
          open={!!this.state.modalWindowId}
          onClose={() => {
            this.setModalWindowId(null);
          }}
          data-test-id={"meterNoteModal"}
        >
          <Box sx={webStyle.modalBox}>
            <Box sx={webStyle.modalTitleBlock}>
              <Typography sx={webStyle.modalTitle}>
                {"Meter #  "}
                {this.state.premiseMeterList.find(({ id }) => id === this.state.currentMeter)?.meter_number}
                {" - "}
                {this.state.tenants.find(({ id }) => id === this.state.premiseMeterList.find(({ id }) => id === this.state.currentMeter)?.tenant_id)?.tenant_name}
              </Typography>
              <Button
                sx={webStyle.modalCloseButton}
                variant="text"
                onClick={() => this.setModalWindowId(null)}
              >
                <img src={closeIcon} alt="Logo" style={{ height: "24px" }} />
              </Button>
            </Box>
            <Box component="div" sx={webStyle.modalScrollBox}>
              <Box sx={webStyle.modalBlock}>
                <Typography sx={webStyle.modalBlockLabel}>
                  {configJSON.meterNotesText}
                </Typography>
                <Typography sx={webStyle.modalBlockValue}>
                  {this.state.premiseMeterList.find(({ id }) => id === this.state.currentMeter)?.meter_note}
                </Typography>
              </Box>
              <Box sx={webStyle.modalCloseButtonBlock}>
                <button
                  style={webStyle.closeButton}
                  data-test-id="closeButton"
                  type="button"
                  onClick={() => this.setModalWindowId(null)}
                >
                  <Typography sx={webStyle.buttonText}>{configJSON.closeButtonText}</Typography>
                </button>
              </Box>
            </Box>
          </Box>
        </Modal>
      ) : ('')}
      <Box sx={webStyle.addReadingTitle}>
        <Typography sx={webStyle.formTitle}>{configJSON.step2TitleText}</Typography>
        <Typography sx={webStyle.formSubTitle}>
          {configJSON.step2SubTitleText}
        </Typography>
      </Box>
      <button
        data-test-id="goToHome"
        style={webStyle.backButton}
        onClick={() => {
          this.setStep(1);
        }}
      >
        <img src={backArrowIcon} alt="" style={webStyle.btnIcon} />
        <Typography sx={webStyle.typographyTitleText}>
          {configJSON.backToHome}
        </Typography>
      </button>
      <Box sx={webStyle.tableWrapper(this.state.totalPages)}>
        <Box sx={webStyle.metersTable}>
          <Box sx={webStyle.metersTableHead}>
            <Typography sx={webStyle.headerMeterTitle}>
              {configJSON.meterNumberText}
            </Typography>
            <Typography sx={webStyle.headerTenantTitle}>
              {configJSON.tenantText}
            </Typography>
            <Typography sx={webStyle.headerMeterNoteTitle}>
              {configJSON.meterNoteText}
            </Typography>
            <Typography sx={webStyle.headerLastRecordTitle}>
              {configJSON.lastRecordText}
            </Typography>
          </Box>
          {this.state.currentReadings.map((meter, index) => (
            <Box sx={webStyle.metersTableBody} key={index} onClick={() => this.setCurrentMeter(meter.id)}>
              <Box sx={webStyle.propertyMeterItem}>
                <Typography sx={webStyle.propertyMeterNumber} data-test-id={`meter.${meter.id}`} onClick={() => { 
                    this.setCurrentMeter(meter.id)
                    this.setState({ step:3 });
                  }}
                >
                  {meter.meter_number} 
                </Typography>
                {meter.meter_readed	=== true && (<img src={successIcon} style={webStyle.successIcon}/>)}
              </Box>
              <Box sx={webStyle.propertyTenantItem}>
                {this.state.tenants.find(({ id }) => id === meter.tenant_id)?.tenant_name}
              </Box>
              <Box sx={webStyle.propertyMeterNoteItem}>
                <Typography sx={webStyle.meterNote}>{meter.meter_note || " - "}</Typography>
                {meter.meter_note && (
                  <Typography 
                    sx={webStyle.readMore} 
                    data-test-id={`readMore.${meter.id}`} 
                    onClick={() => this.setModalWindowId(meter.id)}
                  >
                    {configJSON.readMoreText}
                  </Typography>
                )}
              </Box>
              <Box sx={webStyle.propertyLastRecordItem}>{moment(meter.date_of_last_reading).format('l LT')}</Box>
            </Box>
          ))}
        </Box>
      </Box>
            {this.state.totalPages > 1 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "20px",
                  marginBottom: "5%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexGrow: 1,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* Previous Button */}
                  <button
                    onClick={() => this.handlePrevPage()}
                    data-test-id="previous-button"
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                      marginRight: "4px",
                    }}
                    disabled={this.state.currentPage === 1}
                  >
                    <KeyboardArrowLeftRoundedIcon
                      sx={
                        this.state.currentPage === 1
                          ? webStyle.chevronIconDisabled
                          : webStyle.chevronIconActive
                      }
                    />
                  </button>

                  {this.state.totalPages <= 3 ? (
                    [...Array(this.state.totalPages).keys()].map((page) => (
                      <button
                        key={page + 1}
                        data-test-id={"page-button"}
                        style={{
                          marginRight: "4px",
                          width:
                            this.state.currentPage === page + 1
                              ? "24px"
                              : "16px",
                          height: "24px",
                          backgroundColor:
                            this.state.currentPage === page + 1
                              ? "#DBF5FF"
                              : "inherit",
                          color:
                            this.state.currentPage === page + 1
                              ? "#1A7BA4"
                              : "#64748B",
                          border: "none",
                          textDecoration:
                            this.state.currentPage !== page + 1
                              ? "underline"
                              : "none",
                          borderRadius: "8px",
                          fontWeight:
                            this.state.currentPage !== page + 1 ? 400 : 700,
                          cursor: "pointer",
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() => this.handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </button>
                    ))
                  ) : (
                    <>
                      {this.state.currentPage !== 1 && (
                        <button
                          data-test-id="ellipsis"
                          style={{
                            marginRight: "4px",
                            width: "16px",
                            height: "24px",
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() => this.handlePageChange(1)}
                        >
                          1
                        </button>
                      )}

                      {this.state.currentPage > 3 && (
                        <span style={{ marginRight: "4px", color: "#64748B" }}>
                          ...
                        </span>
                      )}

                      {this.state.currentPage > 2 && (
                        <button
                          data-test-id="middle-page"
                          style={{
                            marginRight: "4px",
                            width: "24px",
                            height: "24px",
                            backgroundColor: "inherit",
                            color: "#64748B",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage - 1)
                          }
                        >
                          {this.state.currentPage - 1}
                        </button>
                      )}
                      <button
                        data-test-id="middle-page2"
                        style={{
                          marginRight: "4px",
                          width: "24px",
                          height: "24px",
                          backgroundColor: "#DBF5FF",
                          color: "#1A7BA4",
                          border: "none",
                          borderRadius: "8px",
                          cursor: "pointer",
                          fontWeight: 700,
                          fontSize: "12px",
                          fontFamily: "'Inter', sans-serif",
                        }}
                        onClick={() =>
                          this.handlePageChange(this.state.currentPage)
                        }
                      >
                        {this.state.currentPage}
                      </button>

                      {this.state.currentPage < this.state.totalPages - 1 && (
                        <button
                          data-test-id="middle-page3"
                          style={{
                            marginRight: "4px",
                            width: "16px",
                            height: "24px",
                            color: "#64748B",
                            backgroundColor: "inherit",
                            border: "none",
                            textDecoration: "underline",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.currentPage + 1)
                          }
                        >
                          {this.state.currentPage + 1}
                        </button>
                      )}

                      {this.state.currentPage < this.state.totalPages - 2 && (
                        <span style={{ marginRight: "4px", color: "#64748B" }}>
                          ...
                        </span>
                      )}

                      {this.state.currentPage !== this.state.totalPages && (
                        <button
                          data-test-id="last-page"
                          style={{
                            marginRight: "4px",
                            width:
                              this.state.currentPage === this.state.totalPages
                                ? "24px"
                                : "16px",
                            height: "24px",
                            backgroundColor:
                              this.state.currentPage === this.state.totalPages
                                ? "#DBF5FF"
                                : "inherit",
                            color:
                              this.state.currentPage === this.state.totalPages
                                ? "#1A7BA4"
                                : "#64748B",
                            border: "none",
                            textDecoration:
                              this.state.currentPage !== this.state.totalPages
                                ? "underline"
                                : "none",
                            borderRadius: "8px",
                            cursor: "pointer",
                            fontSize: "12px",
                            fontFamily: "'Inter', sans-serif",
                            fontWeight:
                              this.state.currentPage !== this.state.totalPages
                                ? 400
                                : 700,
                          }}
                          onClick={() =>
                            this.handlePageChange(this.state.totalPages)
                          }
                        >
                          {this.state.totalPages}
                        </button>
                      )}
                    </>
                  )}

                  <button
                    data-test-id="next-page"
                    onClick={() => this.handleNextPage()}
                    style={{
                      backgroundColor: "inherit",
                      border: "none",
                      cursor: "pointer",
                      padding: 0,
                    }}
                    disabled={this.state.currentPage === this.state.totalPages}
                  >
                    <KeyboardArrowRightRoundedIcon
                      sx={
                        this.state.currentPage === this.state.totalPages
                          ? webStyle.chevronIconDisabled
                          : webStyle.chevronIconActive
                      }
                    />
                  </button>
                </Box>

                <Box sx={{ marginRight: "10px", width: "120px" }}>
                  <Typography
                    fontFamily={'"Nunito Sans", sans-serif'}
                    fontSize={"12px"}
                    color={"grey"}
                  >
                    {this.state.firstItemIndex + 1}-{this.state.lastItemIdex} of{" "}
                    {this.state.filteredItemsCount} results
                  </Typography>
                </Box>
              </Box>
            )}
    </Box>
  )

  submitReading = () => { 
    const currentPremiseMeter = this.state.premiseMeterList.find((pm) => pm.id == this.state.currentMeter);
    const last_reading = Number(currentPremiseMeter?.last_reading);
    const date_of_last_reading = moment(currentPremiseMeter?.date_of_last_reading).format('l LT');
 
    let metrics = "";
    if (currentPremiseMeter?.metric === "standard") {
      metrics = "Standart" + (currentPremiseMeter?.volume === "cu_ft" ? " cu ft" : " gallons")
    } else if (currentPremiseMeter?.metric === "ten_cuft_gallons") {
      metrics = "10" + (currentPremiseMeter?.volume === "cu_ft" ? " cu ft" : " gallons")
    } else {
      metrics = "100" + (currentPremiseMeter?.volume === "cu_ft" ? " cu ft" : " gallons")
    }
    return (
      <Box sx={webStyle.signUpFormContainer}>
        <Box sx={webStyle.addReadingTitle}>
          <Typography sx={webStyle.formTitle}>
            {"Meter #  "}
            {this.state.premiseMeterList.find(({ id }) => id === this.state.currentMeter)?.meter_number}
            {" - "}
            {this.state.tenants.find(({ id }) => id === this.state.premiseMeterList.find(({ id }) => id === this.state.currentMeter)?.tenant_id)?.tenant_name}
          </Typography>
          <Typography sx={webStyle.formSubTitle}>
            {configJSON.step3SubTitleText}
          </Typography>
        </Box>
        <Formik
          initialValues={{
            premise: null,
            meterNumber: null,
            currReading: "",
            consumption: "",
            note: "",
            files: [],
          }}
          validateOnMount={true}
          validateOnChange={true}
          validationSchema={this.createReadingSchema}
          onSubmit={() => {
            this.setLoading(true);
            const { id: meter_id = 0, tenant_id = 0, property_id = 0 } = this.state.premiseMeterList
              .find((pm) => pm.id == this.state.currentMeter) || {};

            this.addMeterReadingData(property_id, tenant_id, meter_id);
          }}
          data-test-id="AddRadingStepTree"
        >
          {({ values, errors, setFieldValue, handleSubmit, resetForm }) => (
            <form style={webStyle.formStyle}>
              {this.state.readingLoading ? (
                <Box sx={webStyle.loaderWrapper} data-test-id="loaderWrapper">
                  <CircularProgress sx={webStyle.loader} />
                </Box>
              ) : ""}
              <Box sx={webStyle.formContainer}>
                <Box sx={webStyle.fieldControl}>
                  <Box sx={webStyle.prevTitle}>
                    <Box sx={webStyle.prevEmptyTitle}>
                      <Typography sx={webStyle.inputLabel}>
                        {configJSON.previousReadingText(metrics)}
                      </Typography>
                      {!last_reading ? (
                        <Typography
                          style={{...webStyle.aditionInputLabel, marginLeft: '4px'}}
                        >
                          {configJSON.notAvaliableText}
                        </Typography>
                      ) : ("")}
                    </Box>
                    {(last_reading && date_of_last_reading) ? (
                      <Typography
                        style={webStyle.aditionInputLabel}
                      >
                        {date_of_last_reading}
                      </Typography>
                    ) : ("")}
                  </Box>
                  {last_reading ? (
                    <input
                      style={webStyle.inputDisabledElement}
                      data-test-id="prevReading"
                      type="text"
                      value={last_reading}
                      disabled
                    />
                  ) : ("")}
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <Typography sx={webStyle.inputLabel}>
                    {configJSON.currentReadingText(metrics)}
                  </Typography>
                  <input
                    style={webStyle.inputElement}
                    data-test-id="currReading"
                    type="text"
                    value={values?.currReading}
                    onChange={(event) => {
                      const currentReading = Number(event.target.value);
                      setFieldValue(
                        "consumption",
                        currentReading - last_reading
                      );
                      setFieldValue("currReading", event.target.value);
                    }}
                  />
                  {this.state.validate && errors.currReading && (
                    <Tooltip
                      open={Boolean(errors.currReading)}
                      title={errors.currReading}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img
                        src={errorIcon}
                        alt=""
                        style={webStyle.errorIcon}
                      />
                    </Tooltip>
                  )}
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <Typography sx={webStyle.inputLabel}>
                    {configJSON.consumptionText(metrics)}
                  </Typography>
                  <input
                    style={webStyle.inputDisabledElement}
                    data-test-id="consumption"
                    type="text"
                    disabled
                    value={values?.consumption || (Number(values?.currReading) - last_reading)}
                  />
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <Typography sx={webStyle.inputLabel}>
                    {configJSON.meterReadingNoteText}
                  </Typography>
                  <textarea
                    style={
                      this.state.noteError
                        ? webStyle.textAreaErrorElement
                        : webStyle.textAreaElement
                    }
                    data-test-id="note"
                    value={values?.note}
                    onChange={(event) => {
                      setFieldValue("note", event.target.value);
                    }}
                  />
                  {this.state.validate && errors.note && (
                    <Tooltip
                      open={Boolean(errors.note)}
                      title={errors.note}
                      componentsProps={tooltipStyles}
                      arrow
                    >
                      <img
                        src={errorIcon}
                        alt=""
                        style={webStyle.errorIcon}
                      />
                    </Tooltip>
                  )}
                  <Typography
                    sx={
                      this.state.noteError
                        ? webStyle.textAreaErrorComent
                        : webStyle.textAreaComent
                    }
                  >
                    {configJSON.enterNoteIfText}
                  </Typography>
                </Box>
                <Box sx={webStyle.fieldControl}>
                  <Dropzone
                    onDrop={([file]) => {
                      const maxSizeError = file.size > 5 * 1024 * 1024;
                      const acceptedTypeError = !acceprtFileType.includes(file.type);

                      if (maxSizeError) {
                        this.setFileError(configJSON.tooBigFile);
                      } else if (acceptedTypeError) {
                        this.setFileError(configJSON.unAccepteedTypeFile);
                      } else {
                        this.setFileError("");
                        setFieldValue("files", [...values.files, file]);
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) =>
                      values.files.length ? (
                        <Box
                          sx={webStyle.fileListContainer}
                          {...getRootProps()}
                        >
                          <Box sx={webStyle.fileListBlock}>
                            {values.files.map((file, index) => {
                              var tmppath = URL.createObjectURL(file);
                              return (
                                <Box sx={webStyle.imageBlock} key={index}>
                                  <Box
                                    sx={webStyle.removeeImageBlock}
                                    data-test-id={`removeImage.${index}`}
                                    onClick={() => {
                                      const fileList = values.files;
                                      const filteredFileList = fileList.filter((_, idx) => idx !== index);
                                      setFieldValue("files", filteredFileList);
                                      this.setFileError("");
                                    }}
                                  >
                                    <img
                                      src={removeIcon}
                                      style={webStyle.removeImage}
                                    />
                                  </Box>
                                  <img
                                    src={tmppath}
                                    style={webStyle.uploadedImage}
                                  />
                                </Box>
                              );
                            })}
                            <Box sx={webStyle.addImageButton} component="label">
                              <VisuallyHiddenInput
                                type="file"
                                data-test-id="uploadZone"
                                accept={acceprtFileType.join(', ')}
                                onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                  const fileList = Array.from((event.target as HTMLInputElement).files as FileList);
                                  const maxSizeError = fileList.some(f => f.size > 5 * 1024 * 1024);
                                  const acceptedTypeError = fileList.some(f => !acceprtFileType.includes(f.type));
                                  if (maxSizeError) {
                                    this.setFileError(configJSON.tooBigFile);
                                  } else if (acceptedTypeError) {
                                    this.setFileError(configJSON.unAccepteedTypeFile);
                                  } else {
                                    this.setFileError("");
                                    setFieldValue("files", [...values.files, ...fileList]);
                                  }
                                }}
                              />
                              <img src={addIcon} style={webStyle.infoIcon} />
                              <Typography sx={webStyle.addImagesText}>
                                {configJSON.addImageText}
                              </Typography>
                            </Box>
                          </Box>
                          <Typography
                            hidden={!this.state.fileError}
                            style={webStyle.tobigFileText}
                          >
                            {this.state.fileError}
                          </Typography>
                        </Box>
                      ) : (
                        <>
                          <Box
                            sx={webStyle.uploadFile(!!this.state.fileError)}
                            component="label"
                            {...getRootProps()}
                          >
                            <VisuallyHiddenInput
                              {...getInputProps()}
                              type="file"
                              accept={acceprtFileType.join(', ')}
                              data-test-id="uploadZone"
                              onChange={(event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                                const fileList = Array.from((event.target as HTMLInputElement).files as FileList);
                                const maxSizeError = fileList.some(f => f.size > 5 * 1024 * 1024);
                                const acceptedTypeError = fileList.some(f => !acceprtFileType.includes(f.type));
                                if (maxSizeError) {
                                  this.setFileError(configJSON.tooBigFile);
                                } else if (acceptedTypeError) {
                                  this.setFileError(configJSON.unAccepteedTypeFile);
                                } else {
                                  this.setFileError("");
                                  setFieldValue("files", [...values.files, ...fileList]);
                                }
                              }}
                              multiple
                            />
                            <img src={uploadIcon} style={webStyle.uploadIcon} />
                            <Box sx={webStyle.uploadFileText}>
                              <Typography sx={webStyle.uploadFileClickText}>
                                {configJSON.clickToUploadText}
                              </Typography>
                              <Typography sx={webStyle.uploadFiledragAndDropText}>
                                {" "}{configJSON.dragAndDropText}
                              </Typography>
                            </Box>
                            <Typography sx={webStyle.uploadFileTypesText}>
                              {configJSON.imageTypeText}
                            </Typography>
                          </Box>
                          <Typography
                            hidden={!this.state.fileError}
                            style={webStyle.tobigFileText}
                          >
                            {this.state.fileError}
                          </Typography>
                        </>
                      )
                    }
                  </Dropzone>
                </Box>
                <Box sx={webStyle.fieldControlSecontStepGroup}>
                  <button
                    style={webStyle.prevButton}
                    data-test-id="prevBtn"
                    type="button"
                    onClick={() => {
                      this.setState({ step: 2 });
                      this.setValidate(false);
                      resetForm();
                    }}
                  >
                    <img src={leftArrowIcon} style={webStyle.infoIcon} />
                    <Typography sx={webStyle.buttonText}>{configJSON.prevButtonText}</Typography>
                  </button>
                  <button
                    style={webStyle.submitButton()}
                    data-test-id="submitBtn"
                    type="button"
                    onClick={() => {
                      this.setFormDate(values);
                      this.setValidate(true);
                      if (Object.keys(errors).length == 0) {
                        handleSubmit();
                        resetForm();
                      }
                    }}
                  >
                    <Typography sx={webStyle.buttonText}>
                      {configJSON.submitButtonTeext}
                    </Typography>
                  </button>
                </Box>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    )
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <Box sx={webStyle.addReadingContainer}>
        <Box sx={webStyle.addReadingBlock}>
          <Box role="tabpanel" hidden={this.state.step !== 1}>
            { this.selectProperty() }
          </Box>
          <Box role="tabpanel" hidden={this.state.step !== 2}>
            { this.selectMeter() }
          </Box>
          <Box role="tabpanel" hidden={this.state.step !== 3}>
            { this.submitReading() }
          </Box>
        </Box>
      </Box>

      //Merge Engine End DefaultContainer
      // Customizable Area Start
    );
  }
}
// Customizable Area Start

const tooltipStyles = {
  tooltip: {
    sx: {
      color: '#FFFFFF',
      fontFamily: "'Inter', sans-serif",
      fontSize: '13px',
      lineHeight: '18px',
      fontWeight: '400',
      padding: '8px 12px',
      backgroundColor: '#1E293B',
      borderRadius: '8px',
      width: 'fit-content',
      maxWidth: '480px',
      '& .MuiTooltip-arrow': {
        color: '#1E293B',
      },
    }
  }
};

const webStyle = {
  addReadingContainer: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#F8FAFC",
    margin: 'auto 0',
    height: '100%',
  },
  addReadingBlock: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  signUpFormContainer: {
    margin: "auto",
    maxWidth: "600px",
    width: "80%",
  },
  meterListTableContainer: {
    margin: "auto",
    maxWidth: "1000px",
    width: "80%",
  },
  addReadingTitle: {
    margin: "20px 10px",
  },
  formContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
  },
  formTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "29px",
    marginBottom: "8px",
    color: '#0F172A',
    inlineSize: "100%",
    overflowWrap: "break-word",
    "@media (max-width: 500px)": {
      fontSize: "22px",
    },
  },
  formSubTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    marginBottom: "20px",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    margin: "10px",
    position: "relative",
  },
  prevTitle: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  prevEmptyTitle: {
    display: "flex",
    flexDirection: "row",
    "@media (max-width: 500px)": {
      flexDirection: "column",
    },
  },
  fieldControlFirstStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  fieldControlSecontStepGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  inputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  aditionInputLabel: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#334155",
    marginBottom: "5px",
    alignItems: "center",
    display: "flex",
  },
  inputElement: {
    fontFamily: "'Inter', sans-serif",
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    fontSize: '16px',
  },
  inputDisabledElement: {
    fontFamily: "'Inter', sans-serif",
    height: "56px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: "#F1F5F9",
    fontSize: '16px',
  },
  textAreaElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontSize: '16px',
    fontFamily: "'Inter', sans-serif",
  },
  textAreaErrorElement: {
    height: "139px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #F87171",
    fontSize: '16px',
    fontFamily: "'Inter', sans-serif",
  },
  textAreaComent: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#64748B",
    marginTop: "5px",
    marginBottom: "5px",
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  textAreaErrorComent: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#DC2626",
    marginTop: "5px",
    marginBottom: "5px",
    "@media (max-width: 500px)": {
      fontSize: "12px",
    },
  },
  selectItem: (selected: boolean = false) => ({
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: selected ? 600 : 400,
    lineHeight: "24px",
    color: selected ? "#1A7BA4" : "#0F172A",
    margin: "0px 8px",
    borderRadius: "8px",
    backgroundColor: selected ? "#E8FBFF !importatnt" : '#FFFFFF !importatnt',
  }),
  selectInput: (startOption: boolean = false) => ({
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    height: '56px',
    color: startOption ? "#94A3B8" : "#0F172A",
    backgroundColor: '#FFFFFF',
    flex: "auto",
    "& fieldset": {
      border: "1px solid #CBD5E1",
      borderRadius: "8px",
    },
    "& fieldset:hover": {
      border: "1px solid #CBD5E1 !important",
      borderRadius: "8px",
    },
  }),
  autocompleteField: () => ({
    fontFamily: "'Inter', sans-serif",
    height: '56px',
    fontSize: '16px',
    backgroundColor: "#FFFFFF", // Change to white when editing
    borderRadius: '8px',
    color: "#0F172A",
    width: '100%',
    fontWeight: 400,
    lineHeight: "24px",
    '& .MuiFormControl-root .MuiInputBase-root ': {
      borderRadius: '8px',
      border: "1px solid #CBD5E1",
      height: '56px',
      fontFamily: "'Inter', sans-serif",
      color: "#0F172A",
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: "24px",
    },
    '& fieldset': {
      border: "none",
    },
    '& input:disabled': {
      color: '#0F172A',
      backgroundColor: '#F1F5F9',
      "-webkit-text-fill-color": "#0F172A",
    },
    "& input::-webkit-input-placeholder": {
      color: "#94A3B8",
      opacity: 1,
    },
    "& input::-moz-placeholder": {
      color: "#94A3B8",
      opacity: 1,
    },
    "& input:-ms-input-placeholder": {
      color: "#94A3B8",
      opacity: 1,
    },
    "& input::-ms-input-placeholder": {
      color: "#94A3B8",
      opacity: 1,
    },
    "& .MuiAutocomplete-clearIndicator": {
      display: "none",
    },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
      fontFamily: "'Inter', sans-serif",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      color: "#0F172A",
      margin: "0px 8px",
      borderRadius: "8px",
      backgroundColor: '#FFFFFF !importatnt',
    },
    "& + .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']": {
      fontWeight: 600,
      color: "#1A7BA4",
      backgroundColor: "#E8FBFF !importatnt",
    },
  }),
  prevButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "30px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#F8FAFC",
    color: "#1A7BA4",
    padding: "0px 16px 0px 8px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  submitButton: (disabled: boolean = false) => ({
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "30px 0 0 16px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    opacity: disabled ? 0.6 : 1,
    cursor: disabled ? "not-allowed" : "pointer"
  }),
  closeButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    margin: "16px 0 0 16px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  addImageButton: {
    margin: "27px 0px 27px 16px;",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#ECF3F7",
    padding: "0px 16px",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    "@media (max-width: 500px)": {
      fontSize: "14px",
    },
  },
  informationBlock: {
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
  },
  informationFieldBlock: {
    margin: "18px 6px",
  },
  informationTitle: {
    color: "#94A3B8",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
    alignItems: "center",
    display: "flex",
    margin: "0px 5px",
    "@media (max-width: 500px)": {
      fontSize: "13px",
    },
  },
  informationField: {
    color: "#0F172A",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    margin: "6px 0px",
    "@media (max-width: 500px)": {
      fontSize: "13px",
    },
  },
  informationTitleContainer: {
    display: "flex",
    flexDirection: "row",
    padding: "5px 0px",
  },
  infoIcon: {
    width: "20px",
    height: "20px",
  },
  successIcon: {
    width: "20px",
    height: "20px",
    marginLeft: "5px",
  },
  reemoveBookingIcon: {
    width: "20px",
    height: "20px",
    padding: "6px 0px",
  },
  bookingField: {
    display: "flex",
    flexDirection: "row",
  },
  removeBookingContainer: {
    display: "flex",
    flexDirection: "row",
  },
  removeBookingButton: {
    display: "flex",
    margin: "12px 19px",
    flexDirection: "row",
    cursor: "pointer",
  },
  removeBookingContaineer: {
    flex: "auto",
    width: "50%",
    "@media (max-width: 500px)": {
      flex: "none",
      width: "auto",
    },
  },
  removeBookingText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",

    alignItems: "center",
    display: "flex",
    color: "#475569",

    "@media (max-width: 500px)": {
      display: "none",
    },
  },
  tableWrapper: (totalPages: number) => ({
    minHeight: totalPages > 1 ? '535px' : 'unset',
    marginBottom: "30px",
    "@media (max-width: 767px)": {
      overflow: "auto",
    },
  }),
  metersTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    "@media (max-width: 767px)": {
      minWidth: "600px",
    },
  },
  metersTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    backgroundColor: 'rgba(248, 250, 252, 1)',
    borderRadius: '12px',
  },
  metersTableDataHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    width: "calc(100% - 340px)",
  },
  metersTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    '&:last-of-type': {
      borderRadius: '0 0 12px 12px',
    }
  },
  metersTableDataBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
    width: "calc(100% - 340px)",
  },
  headerMeterTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "20%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerTenantTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "20%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerMeterNoteTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "40%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  headerLastRecordTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "20%",
    overflow: "hidden",
    margin: "12px 4px",
  },
  propertyMeterItem: {
    width: "20%",
    margin: "13px 4px",
    alignItems: "center",
    display: "flex",
    wordBreak: 'break-word',
    backgroundColor: '#FFFFFF',
    
  },
  propertyMeterNumber: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#0F172A",
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    cursor: "pointer",
  },
  propertyTenantItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "20%",
    margin: "auto 4px",
    alignItems: "center",
    display: "inline",
    wordBreak: 'break-word',
    backgroundColor: '#FFFFFF',
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  propertyMeterNoteItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "40%",
    margin: "auto 4px",
    alignItems: "center",
    display: "inline-flex",
    wordBreak: 'break-word',
    backgroundColor: '#FFFFFF',
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    flexDirection: "row",
  },
  propertyLastRecordItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "20%",
    margin: "auto 4px",
    alignItems: "center",
    display: "inline",
    wordBreak: 'break-word',
    backgroundColor: '#FFFFFF',
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  meterNote: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "calc(100% - 100px)",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "inline",
    margin: "auto",
  },
  goToHome: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#2563EB",
    width: "100px",
    cursor: "pointer",
  },
  readMore: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textUnderlinePosition: "from-font",
    textDecorationSkipInk: "none",
    color: "#2563EB",
    width: "100px",
    cursor: "pointer",
    marginLeft: "6px",
  },
  modalBox: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50vw",
    height: "auto",
    maxHeight: "80vh",
    backgroundColor: "#FFFFFF",
    border: "none",
    borderRadius: "18px",
    boxShadow: 24,
    p: 4,
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 810px)": {
      width: "65vw",
    },
  },
  modalScrollBox: {
    marginBottom: "75px",
    display: "flex",
    flexDirection: "column",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "32px",
    textAlign: "left",
    flex: 1,
    color: "0F172A",
  },
  modalTitleBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingBottom: "20px",
    marginTop: "-12px",
    marginBottom: "20px",
    borderBottom: "1px solid #CBD5E1",
  },
  modalTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "24px",
    fontWeight: 600,
    lineHeight: "30px",
    color: "#0F172A",
    inlineSize: "100%",
    overflowWrap: "break-word",
    width: "calc(100% - 56px)",
    "@media (max-width: 810px)": {
      fontSize: "20px",
    },
  },
  modalCloseButton: {
    color: "#64748B",
    border: "none",
    backgroundColor: "#F8FAFC",
    borderRadius: '100%',
    width: '56px',
    height: '56px',
    cursor: 'pointer',
    position: "absolute",
    top: "16px",
    right: "32px",
  },
  modalDoubleBlock: {
    display: "flex",
    flexDirection: "row",
  },
  modalBlock: {
    display: "flex",
    margin: "8px 0px",
    flexDirection: "column",
  },
  modalCloseButtonBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    margin: "16px 0px 32px",
    borderTop: "1px solid #CBD5E1",
    bottom: "0px",
    position: "absolute",
    width: "calc(100% - 64px)",
  },
  modalBlockLabel: {
    margin: "0px 0px 10px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "#334155",
  },
  modalBlockValue: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#0F172A",
    margin: "0px",
    border: "1px solid #CBD5E1",
    borderRadius: "8px",
    padding: "15px 10px",
    maxHeight: "280px",
    overflowX: "auto",
    "@media (max-width: 810px)": {
      fontSize: "13px",
    },
  },
  uploadIcon: {
    width: "32px",
    height: "32px",
    margin: "auto",
    display: "flex",
  },
  uploadFile: (error: boolean = false) => ({
    background: "#1A7BA40D",
    border: error ? "1px dashed #F87171" : "1px dashed #1A7BA4",
    width: "100%",
    padding: "15px 0px 20px",
    borderRadius: "14px",
    cursor: "pointr",
  }),
  uploadFileText: {
    display: "flex",
    flexDirection: "row",
    margin: "8px auto",
    justifyContent: "center",
  },
  uploadFileClickText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "12px",
    color: "#64748B",
    marginRight: "6px",
  },
  uploadFiledragAndDropText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    color: "#475569",
  },
  uploadFileTypesText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    color: "#475569",
    justifyContent: "center",
    display: "flex",
  },
  addImagesText: {
    margin: "0px 5px",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
    justifyContent: "center",
    display: "flex",
  },
  removeImage: {
    width: "10px", 
    height: "10px", 
    stroke: "#DC2626"
  },
  uploadedImage: { 
    width: "80px", 
    height: "80px", 
    borderRadius: "8px" 
  },
  removeeImageBlock: { 
    position: "absolute",
    left: "70px",
    top: "0px",
    backgroundColor: "#FEE2E2",
    borderRadius: "10px",
    padding: "5px",
  },
  imageBlock: { 
    width: "80px", 
    height: "80px", 
    position: "relative", 
    padding: "10px 20px 0px 0px" ,
    "&:nth-child(6n)": {
      padding: "10px 0px 0px 0px" ,
    },
  },
  tobigFileText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "24px",
    color: "#DC2626",
  },
  fileListContainer: {
    display: "flex", 
    flexDirection: "column",
  },
  fileListBlock: {
    flexWrap: "wrap",
    display: "flex", 
    flexDirection: "row",
  },
  errorIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 43,
    right: 16,
  },
  backButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    color: "#1A7BA4",
    border: "none",
    backgroundColor: "#F8FAFC",
    display: "flex",
    flexDirection: "row" as const,
    height: "44px",
    alignItems: "center",
    margin: "10px 0px",
    padding: "6px 0px",
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  typographyTitleText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginLeft: "6px",

    color: "#1A7BA4",

    textDecorationLine: "underline",
    textDecorationStyle: "solid",
    textUnderlinePosition: "from-font",
  },
  loaderWrapper: {
    margin: 'auto',
    height: "100%",
    width: "100%",
    zIndex: 2,
    backgroundColor: "#FFFFFF",
    opacity: 0.5,
    position: "absolute"
   },
   formStyle: {
    position: "relative" as "relative",
   },
   loader: {
    color: '#1A7BA4',
    height: '60px',
    width: '60px',
    margin: "calc(50% - 20px)",
   },
   chevronIconActive: {
    "> path": {
      fill: "#475569",
    },
  },
  chevronIconDisabled: {
    "> path": {
      fill: "#64748B",
    },
  },
};
// Customizable Area End
