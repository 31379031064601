Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.getPropertiesApiEndPoint = "bx_block_custom_forms/properties/property_list_for_reading";
exports.getPropertiesApiMethod = "Get";
exports.getPropertiesApiContentType = "application/json";

exports.getReadingCustomerDataApiEndPoint = "bx_block_custom_forms/properties/reading_customer_data";
exports.getReadingCustomerDataApiMethod = "Get";
exports.getReadingCustomerDataApiContentType = "application/json";

exports.getTenantDataApiEndPoint = "bx_block_custom_forms/properties";
exports.getTenantDataApiMethod = "Get";
exports.getTenantDataApiContentType = "application/json";

exports.getPremiseMeterDataApiEndPoint = "bx_block_custom_forms/properties/premise_meters";
exports.getPremiseMeterDataApiMethod = "Get";
exports.getPremiseMeterDataApiContentType = "application/json";

exports.addMeterReadingApiEndPoint = "bx_block_custom_forms/properties";
exports.addMeterReadingApiMethod = "POST";
exports.addMeterReadingApiContentType = "application/json";

exports.step1TitleText = "Select property";
exports.step1SubTitleText = "Select the book # and property of the meter you want to read";
exports.step2TitleText = "Select meter";
exports.step2SubTitleText = "Select the right meter you want to read";
exports.step3SubTitleText = "Enter the reading displayed on the meter";

exports.meterNumberText = "Meter#";
exports.tenantText = "Tenant";
exports.meterNoteText = "Meter Note";
exports.lastRecordText = "Last Record";

exports.bookFieldLabel = "Book #";
exports.bookFieldPlaceholder = "Select one (if applicable)";
exports.propertyFieldLabel = "Property";
exports.propertyFieldPlaceholder = "Start typing...";

exports.meterNotesText = "Meter note";
exports.readMoreText =  "Read more";

exports.prevButtonText =  "Prev";
exports.nextButtonText =  "Next";
exports.submitButtonTeext =  "Submit";
exports.closeButtonText = "Close";

exports.previousReadingText = (metric = "per 10 cu ft") => (`Previous reading (per ${metric})`);
exports.currentReadingText = (metric = "per 10 cu ft") => (`Current reading (per ${metric})`);
exports.consumptionText = (metric = "per 10 cu ft") => (`Consumption (per ${metric})`);
exports.notAvaliableText = "- Not available";

exports.meterReadingNoteText = "Meter reading note";
exports.enterNoteIfText = "Enter a note if you’re not able to enter the reading";

exports.addImageText = "Add images";
exports.clickToUploadText = "Click to upload";
exports.dragAndDropText = "or drag and drop";
exports.imageTypeText = "(png, jpg)";
exports.backToHome = "Back to Home"
exports.clearText = "clear";
exports.managerText = "Building Manager info";
exports.contactInfoText = "Company Contact info";
exports.companyNameText = "Company name";
exports.tooBigFile = "File uploaded should not exceed: 5MB";
exports.unAccepteedTypeFile = "The uploaded file type must be one of the allowed values (image/png, image/jpeg)";
// Customizable Area End