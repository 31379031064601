import React, { useMemo, useState } from "react";
import {
  Typography,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  InputBase,
  IconButton,
  Tooltip,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { searchIcon } from "./assets";
import { styled } from "@mui/material/styles";
import { ProRate, Report } from "./CustomerReports.web";
import AddIcon from "@mui/icons-material/AddRounded";
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import { CustomerEditableTextField } from "./CustomerEditableTextField.web";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { infoIcon, coldWaterIcon, hotWaterIcon } from "./assets";
import { CustomerGallery } from "./CustomerGallery.web";
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded';
import moment from "moment";

export interface Meter {
  id: string;
  present: number;
  previous: number;
  consumptionGallons?: number;
  consumptionCubicFeet?: number;
  consumptionKW?: number;
  rate: string;
  amount: number;
  type?: "Hot" | "Cold";
  comment?: string;
  commentDate?: string;
  images?: string[];
}

export interface Tenant {
  id: string;
  name: string;
  meters: Meter[];
}

interface CustomerCreateEditReportProps {
  generateReport: (report: Report) => void;
  report: Report;
  onCancel: () => void;
  onAddSubmit: () => void;
  isAdd?: boolean;
}

const prices = ['Price 1', 'Price 2', 'Price 3'];

interface ReportCommentDialogProps {
  isOpen: boolean;
}

interface MeterCommentDialogProps {
  isOpen: boolean;
  comment: string;
  date: string;
}

export const CustomerCreateEditReport = ({
  report,
  generateReport,
  isAdd,
  onAddSubmit,
  onCancel,
}: CustomerCreateEditReportProps) => {
  const [internalReport, setInternalReport] = useState<Report>(report);
  const [tenantFilter, setTenantFilter] = useState<string | null>(null);
  const [reportCommentDialogProps, setReportCommentDialogProps] = useState<ReportCommentDialogProps>({ isOpen: false });
  const [meterCommentDialogProps, setMeterCommentDialogProps] = useState<MeterCommentDialogProps>({ isOpen: false, comment: '', date: '' });
  const [editedComment, setEditedComment] = useState("");

  const {
    sumOfPresent,
    sumOfPrevious,
    sumOfConsumptionKW,
    sumOfConsumptionGallons,
    sumOfConsumptionCubicFeet,
    sumOfAmount,
  } = useMemo(
    () =>
      internalReport.tenantsList.reduce(
        (acc, item) => {
          item.meters.forEach((meter) => {
            acc.sumOfPresent += meter.present;
            acc.sumOfPrevious += meter.previous;
            acc.sumOfConsumptionKW += meter.consumptionKW || 0;
            acc.sumOfConsumptionGallons += meter.consumptionGallons || 0;
            acc.sumOfConsumptionCubicFeet += meter.consumptionCubicFeet || 0;
            acc.sumOfAmount += meter.amount;
          });

          return acc;
        },
        {
          sumOfPresent: 0,
          sumOfPrevious: 0,
          sumOfConsumptionKW: 0,
          sumOfConsumptionGallons: 0,
          sumOfConsumptionCubicFeet: 0,
          sumOfAmount: 0,
        }
      ),
    [internalReport.tenantsList]
  );

  const sumOfAmountFormatted = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(sumOfAmount));

  const handleAddProRate = () => {
    const newProRates = [...(internalReport.proRates || [])];

    newProRates.push({
      tenantName: report.tenantsList?.[0].name,
      tenantId: report.tenantsList?.[0].id,
      amount: 100,
    });

    setInternalReport({
      ...internalReport,
      proRates: newProRates,
    });
  };

  const handleProRateChange = (
    fieldName: keyof ProRate,
    value: string | number,
    proRateIndex: number
  ) => {
    const newProRates = internalReport.proRates
      ? [...internalReport.proRates]
      : [];


    newProRates[proRateIndex][fieldName] = value as never;

    setInternalReport({
      ...internalReport,
      proRates: newProRates,
    });
  };

  const handleReportCommentDialogOpen = () => {
    setEditedComment(internalReport.comment || "");
    setReportCommentDialogProps({ isOpen: true });
  };

  const handleCommentDialogCancel = () => {
    setReportCommentDialogProps({ isOpen: false });
  };

  const handleCommentDialogSubmit = () => {
    setInternalReport({
      ...internalReport,
      comment: editedComment,
    });
    setReportCommentDialogProps({ isOpen: false });
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedComment(event.target.value);
  };

  const handleReportEntireRateChange = (value: string) => {
    setInternalReport({
      ...internalReport,
      rate: Number(value),
    });
  };

  const handleReportMeterNumericValueChange = (
    fieldName: keyof Meter,
    value: string,
    tenantIndex: number,
    meterIndex: number
  ) => {
    const newTenantsList = [...internalReport.tenantsList];
    const newMeterList = [...internalReport.tenantsList[tenantIndex].meters];

    newMeterList[meterIndex][fieldName] = Number(value) as never;
    newTenantsList[tenantIndex].meters = newMeterList;

    setInternalReport({
      ...internalReport,
      tenantsList: newTenantsList,
    });
  };

  const handleReportMeterRateChange = (
    value: string,
    tenantIndex: number,
    meterIndex: number
  ) => {
    const newTenantsList = [...internalReport.tenantsList];
    const newMeterList = [...internalReport.tenantsList[tenantIndex].meters];

    newMeterList[meterIndex].rate = value;
    newTenantsList[tenantIndex].meters = newMeterList;

    setInternalReport({
      ...internalReport,
      tenantsList: newTenantsList,
    });
  };

  const handleRemoveTenant = (tenantIndex: number) => {
    setInternalReport({
        ...internalReport,
        tenantsList: internalReport.tenantsList.filter((_, index) => index !== tenantIndex),
    });
  };

  const handleRemoveProRate = (proRateIndex: number) => {
    if (!internalReport.proRates) {
        return;
    }

    setInternalReport({
        ...internalReport,
        proRates: internalReport.proRates.filter((_, index) => index !== proRateIndex),
    });
  }

  const handleGenerateReport = () => generateReport(internalReport);

  return (
    <Box sx={webStyle.tenantsContainer}>
      {!isAdd && (
        <Box sx={webStyle.tenantsTableHeader}>
          <Box sx={webStyle.filterWrapper}>
            <Box sx={webStyle.searchContainer}>
              <Box sx={webStyle.fieldControl}>
                <StyledSearchInput
                    type="text"
                    data-test-id="search"
                    placeholder="Search"
                />
                <img src={searchIcon} alt="" style={webStyle.searchIcon} />
              </Box>
            </Box>
            <Box sx={webStyle.tenantSelectContainer}>
              <label style={webStyle.tenantSelectLabel}>Tenant</label>
              <Select
                renderValue={(select) => select || "Select"}
                displayEmpty
                value={tenantFilter}
                onChange={(event) => setTenantFilter(event.target.value)}
                style={!tenantFilter ? { color: "#94A3B8" } : undefined}
                sx={webStyle.selectTenant}
              >
                {report.tenantsList.map((tenant) => (
                  <MenuItem
                    value={tenant.name}
                    sx={webStyle.selectTenantOption}
                    key={tenant.id}
                  >
                    {tenant.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
          <Box sx={webStyle.actionContainer}>
            <button
              style={webStyle.commentButton}
              onClick={handleReportCommentDialogOpen}
              data-test-id="commentOpen"
            >
              <Typography sx={webStyle.buttonText}>Comment</Typography>
            </button>
            <button
              style={webStyle.generateReportButton}
              onClick={handleGenerateReport}
              data-test-id="generateReport"
            >
              <Typography sx={webStyle.buttonText}>Generate report</Typography>
            </button>
          </Box>
        </Box>
      )}
      <Box>
        <Typography
          fontFamily={"'Inter', sans-serif"}
          fontSize={"16px"}
          color={"#334155"}
          fontWeight={500}
          marginBottom={"16px"}
          marginTop={isAdd ? '20px' : 0}
          data-test-id="resultsText"
        >
          {internalReport.tenantsList.length} results
        </Typography>
      </Box>
      <Box sx={webStyle.tenantsTable}>
        <Box sx={webStyle.tenantsTableHead}>
          <Typography style={webStyle.headerTitle}>TENANTS</Typography>
          <Typography style={webStyle.headerTitle}>METER#</Typography>
          {internalReport.type === "Water" && (
            <Typography style={webStyle.headerTitle}>TYPE</Typography>
          )}
          <Typography style={webStyle.headerTitle}>PRESENT</Typography>
          <Typography style={webStyle.headerTitle}>PREVIOUS</Typography>
          <Box style={{...webStyle.headerTitle, ...(internalReport.type === 'Water' ? { margin: '3px 4px' } : {})}}>
            {internalReport.type === "Water" ? (
              <Box sx={webStyle.tenantConsumptionHeader}>
                <Typography
                  sx={{
                    ...webStyle.tenantConsumptionHeaderText,
                    ...webStyle.tenantConsumptionHeaderTextTitle,
                  }}
                >
                  CONSUMPTION
                </Typography>
                <Box sx={webStyle.tenantConsumption}>
                  <Typography sx={webStyle.tenantConsumptionHeaderText}>
                    GALLONS
                  </Typography>
                  <Typography sx={webStyle.tenantConsumptionHeaderText}>
                    CU FT
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Typography sx={webStyle.tenantConsumptionHeaderText}>
                CONSUMPTION kW
              </Typography>
            )}
          </Box>
          <Box style={webStyle.headerTitle}>
            <Box
              sx={{
                display: "flex",
                gap: "8px",
                alignItems: "center",
                width: "fit-content",
              }}
            >
              <Typography sx={webStyle.tenantConsumptionHeaderText}>
                RATE
              </Typography>
              <Tooltip
                title={`Rate is in ${
                  internalReport.type === "Water" ? "Cubic Feet" : "kW"
                }`}
                componentsProps={tooltipStyles}
                placement="top"
                arrow
              >
                <img
                  style={{ width: "20px", height: "20px" }}
                  src={infoIcon}
                  alt="Info"
                />
              </Tooltip>
            </Box>
          </Box>
          <Typography style={{...webStyle.headerTitle, width: '150%'}}>AMOUNT</Typography>
        </Box>
        <Box sx={webStyle.tenantsTableBody}>
          <Box sx={webStyle.tenantsDataTableBody}>
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>
              Entire premises
            </Box>
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>
              {internalReport.id}
            </Box>
            {internalReport.type === "Water" && (
              <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>-</Box>
            )}
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>
              {sumOfPresent}
            </Box>
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>
              {sumOfPrevious}
            </Box>
            <Box sx={webStyle.tenantItem}>
              {internalReport.type === "Water" ? (
                <Box sx={webStyle.tenantConsumption}>
                  <Typography
                    sx={{ ...webStyle.tenantConsumptionText, fontWeight: 700 }}
                  >
                    {sumOfConsumptionGallons}
                  </Typography>
                  <Typography
                    sx={{ ...webStyle.tenantConsumptionText, fontWeight: 700 }}
                  >
                    {sumOfConsumptionCubicFeet}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  sx={{ ...webStyle.tenantConsumptionText, fontWeight: 700 }}
                >
                  {sumOfConsumptionKW}
                </Typography>
              )}
            </Box>
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700 }}>
              <CustomerEditableTextField
                initialValue={internalReport.rate.toString()}
                onChange={handleReportEntireRateChange}
                isMoneyValue
              />
            </Box>
            <Box sx={{ ...webStyle.tenantItem, fontWeight: 700, width: '150%' }}>
              {sumOfAmountFormatted}
            </Box>
          </Box>
        </Box>
        {internalReport.tenantsList.map(
          (tenant: Tenant, tenantIndex: number) => (
            <Box
              sx={{ ...webStyle.tenantsTableBody, flexDirection: "column" }}
              key={tenant.id}
            >
              {tenant.meters.map((meter: Meter, meterIndex: number) => (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    flexDirection: "row",
                  }}
                  key={meter.id}
                >
                  <Box sx={webStyle.tenantItem}>
                    {meterIndex === 0 ? tenant.name : ""}
                  </Box>
                  <Box sx={webStyle.tenantItem}>{meter.id}</Box>
                  {internalReport.type === "Water" && (
                    <Box sx={{ ...webStyle.tenantItem, gap: "4px" }}>
                      <img
                        src={
                          meter.type === "Cold" ? coldWaterIcon : hotWaterIcon
                        }
                      />
                      <Box>{meter.type}</Box>
                    </Box>
                  )}
                  <Box sx={webStyle.tenantItem}>
                    <CustomerEditableTextField
                      initialValue={meter.present.toString()}
                      onChange={(value: string) =>
                        handleReportMeterNumericValueChange(
                          "present",
                          value,
                          tenantIndex,
                          meterIndex
                        )
                      }
                    />
                  </Box>
                  <Box sx={webStyle.tenantItem}>
                    <CustomerEditableTextField
                      initialValue={meter.previous.toString()}
                      onChange={(value: string) =>
                        handleReportMeterNumericValueChange(
                          "previous",
                          value,
                          tenantIndex,
                          meterIndex
                        )
                      }
                    />
                  </Box>
                  <Box sx={webStyle.tenantItem}>
                    {internalReport.type === "Water" ? (
                      <Box sx={webStyle.tenantConsumption}>
                        <Typography sx={webStyle.tenantConsumptionText}>
                          {meter.consumptionGallons}
                        </Typography>
                        <Typography sx={webStyle.tenantConsumptionText}>
                          {meter.consumptionCubicFeet}
                        </Typography>
                      </Box>
                    ) : (
                      <Typography sx={webStyle.tenantConsumptionText}>
                        {meter.consumptionKW}
                      </Typography>
                    )}
                  </Box>
                  <Box sx={webStyle.tenantItem}>
                    <StyledSelect
                        value={meter.rate}
                        onChange={(event) =>
                            handleReportMeterRateChange(event.target.value as string, tenantIndex, meterIndex)
                        }
                    >
                        {prices.map((price) => (
                            <MenuItem value={price} key={price} sx={webStyle.selectTenantOption}>
                                <Box sx={webStyle.selectRateText}>
                                    {price}
                                </Box>
                            </MenuItem>
                        ))}
                    </StyledSelect>
                  </Box>
                  <Box sx={{...webStyle.tenantItem, width: '150%'}}>
                    <CustomerEditableTextField
                      initialValue={meter.amount.toString()}
                      onChange={(value: string) =>
                        handleReportMeterNumericValueChange(
                          "amount",
                          value,
                          tenantIndex,
                          meterIndex
                        )
                      }
                      isMoneyValue
                    />
                    {!isAdd && (
                      <>
                        <Box sx={{ marginLeft: '8px' }}>
                          <CustomerGallery urls={meter.images || []} />
                        </Box>
                        <Box sx={{ marginLeft: '8px' }}>
                          <IconButton 
                            sx={webStyle.meterCommentButton(!!meter.comment)}
                            onClick={() => setMeterCommentDialogProps({ isOpen: true, comment: meter.comment || '', date: meter.commentDate || '' })}
                            data-test-id="meterCommentOpen"
                          >
                            <ChatBubbleOutlineRoundedIcon sx={webStyle.commentIcon} />
                            {/* {meter.comment && (
                              <Box sx={webStyle.commentBadge} data-test-id="commentBadge" />
                            )} */}
                          </IconButton>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              ))}
              {isAdd && (
                  <IconButton sx={webStyle.removeItemButton} onClick={() => handleRemoveTenant(tenantIndex)} data-test-id="removeTenant">
                      <RemoveRoundedIcon sx={webStyle.removeItemIcon} />
                  </IconButton>
              )}
            </Box>
          )
        )}
      </Box>
      {internalReport.proRates && internalReport.proRates?.length > 0 && (
        <>
          <Typography sx={webStyle.proRateTitle}>Pro-rate</Typography>
          <Box sx={webStyle.tenantsTable}>
            {internalReport.proRates.map((proRate, proRateIndex) => (
              <Box sx={webStyle.proRateTableRow} key={proRate.tenantId} data-test-id="proRateRow">
                <Box
                  sx={{
                    ...webStyle.tenantItem,
                    margin: "0 4px",
                    fontWeight: 700,
                  }}
                >
                  {!isAdd ? (
                    proRate.tenantName
                  ) : (
                    <Select
                      value={proRate.tenantName}
                      sx={webStyle.selectProRateTenant}
                      onChange={(event) =>
                        handleProRateChange(
                          "tenantName",
                          event.target.value,
                          proRateIndex
                        )
                      }
                    >
                      {report.tenantsList.map((tenant) => (
                        <MenuItem
                          value={tenant.name}
                          sx={webStyle.selectTenantOption}
                          key={tenant.id}
                        >
                          {tenant.name}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </Box>
                <Typography sx={{ ...webStyle.tenantItem, margin: "0 4px" }} />
                <Typography sx={{ ...webStyle.tenantItem, margin: "0 4px" }} />
                <Typography sx={{ ...webStyle.tenantItem, margin: "0 4px" }} />
                <Typography sx={{ ...webStyle.tenantItem, margin: "0 4px" }} />
                <Typography sx={{ ...webStyle.tenantItem, margin: "0 4px" }} />
                {internalReport.type === "Water" && (
                  <Typography
                    sx={{ ...webStyle.tenantItem, margin: "0 4px" }}
                  />
                )}
                <Box
                  sx={{
                    ...webStyle.tenantItem,
                    margin: "0 4px",
                    fontWeight: 700,
                  }}
                >
                  <CustomerEditableTextField
                    initialValue={proRate.amount.toString()}
                    onChange={(value: string) =>
                      handleProRateChange("amount", value, proRateIndex)
                    }
                    valueClass={{ width: 'max-content' }}
                    isMoneyValue
                  />
                </Box>
                {isAdd && (
                    <IconButton sx={webStyle.removeItemButton} onClick={() => handleRemoveProRate(proRateIndex)}>
                        <RemoveRoundedIcon sx={webStyle.removeItemIcon} />
                    </IconButton>
                )}
              </Box>
            ))}
          </Box>
        </>
      )}
      {isAdd && (
        <button
          style={webStyle.addNewProRateButton}
          type="button"
          onClick={handleAddProRate}
          data-test-id="addProRate"
        >
          <AddIcon style={{ ...webStyle.btnIcon, color: "#1A7BA4" }} />
          <Typography style={webStyle.typographyTitleText1}>
            Add a pro-rate
          </Typography>
        </button>
      )}
      {!isAdd && (
        <Box sx={webStyle.estimatedReadingWrapper}>
          <Typography sx={webStyle.asterix}>*</Typography>
          <Typography sx={webStyle.estimatedReadingText}>
            estimated reading
          </Typography>
        </Box>
      )}
      {isAdd && (
        <Box sx={webStyle.buttonsWrapper}>
          <button
            style={{...webStyle.cancelButton, backgroundColor: '#F8FAFC'}}
            data-test-id="cancelBtn"
            type="button"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            style={webStyle.submitButton}
            data-test-id="submitBtn"
            type="button"
            onClick={onAddSubmit}
          >
            Save
          </button>
        </Box>
      )}
      <Dialog
        open={reportCommentDialogProps.isOpen}
        onClose={handleCommentDialogCancel}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "32px",
            maxHeight: "calc(100% - 120px)",
          },
        }}
      >
        <DialogTitle style={{ padding: "0 0 32px", position: "relative" }}>
          <Typography variant="h6" sx={webStyle.dialogTitleText}>
            {`${internalReport.comment ? 'Edit' : 'Add'} comment`}
          </Typography>
          <IconButton
            onClick={handleCommentDialogCancel}
            style={{
              position: "absolute",
              right: "0",
              top: "-8px",
              width: "56px",
              height: "56px",
              backgroundColor: "#F8FAFC",
              borderRadius: "52px",
            }}
          >
            <CloseRoundedIcon
              sx={{ width: "24px", height: "24px", color: "#64748B" }}
            />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{ padding: 0, overflowY: "unset", margin: "32px 0" }}
        >
          <Box sx={webStyle.commentWrapper}>
            <label style={webStyle.commentLabel}>Comment</label>
            <StyledCommentInput
              value={editedComment}
              onChange={handleCommentChange}
              placeholder="Start typing..."
              rows={7}
              multiline
            />
          </Box>
        </DialogContent>
        <Divider />
        <Box sx={{ ...webStyle.buttonsWrapper, marginTop: "22px" }}>
          <button
            style={webStyle.cancelButton}
            data-test-id="cancelDialogButton"
            type="button"
            onClick={handleCommentDialogCancel}
          >
            Cancel
          </button>
          <button
            style={webStyle.submitButton}
            data-test-id="submitDialogButton"
            type="button"
            onClick={handleCommentDialogSubmit}
          >
            Save
          </button>
        </Box>
      </Dialog>
      <Dialog
        open={meterCommentDialogProps.isOpen}
        onClose={() => setMeterCommentDialogProps({ ...meterCommentDialogProps, isOpen: false })}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          style: {
            borderRadius: "16px",
            padding: "32px",
            maxHeight: "calc(100% - 120px)",
          },
        }}
        data-test-id="meterCommentDialog"
      >
        <DialogTitle style={{ padding: "0 0 32px", position: "relative" }}>
          <Typography variant="h6" sx={webStyle.dialogTitleText}>
            Comment
          </Typography>
          <IconButton
            onClick={() => setMeterCommentDialogProps({ ...meterCommentDialogProps, isOpen: false })}
            style={{
              position: "absolute",
              right: "0",
              top: "-8px",
              width: "56px",
              height: "56px",
              backgroundColor: "#F8FAFC",
              borderRadius: "52px",
            }}
          >
            <CloseRoundedIcon
              sx={{ width: "24px", height: "24px", color: "#64748B" }}
            />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{ padding: 0, overflowY: "unset", margin: "32px 0" }}
        >
          <Box sx={webStyle.commentWrapper}>
            <label style={webStyle.commentLabel}>{meterCommentDialogProps.date ? moment(meterCommentDialogProps.date).format('M/D/YYYY hh:mmA') : ''}</label>
            <StyledCommentInput
              value={meterCommentDialogProps.comment}
              rows={7}
              multiline
              disabled
            />
          </Box>
        </DialogContent>
        <Divider />
        <Box sx={{ ...webStyle.buttonsWrapper, marginTop: "22px" }}>
          <button
            style={webStyle.cancelButton}
            data-test-id="cancelDialogButton"
            type="button"
            onClick={() => setMeterCommentDialogProps({ ...meterCommentDialogProps, isOpen: false })}
          >
            Close
          </button>
        </Box>
      </Dialog>
    </Box>
  );
};

const tooltipStyles = {
  tooltip: {
    sx: {
      color: "#FFFFFF",
      fontFamily: "'Inter', sans-serif",
      fontSize: "13px",
      lineHeight: "18px",
      fontWeight: "400",
      padding: "8px 12px",
      backgroundColor: "#334155",
      borderRadius: "8px",
      width: "fit-content",
      maxWidth: "480px",
      "& .MuiTooltip-arrow": {
        color: "#334155",
      },
    },
  },
};

const StyledSearchInput = styled(InputBase)(() => ({
    height: "44px",
    padding: "12px 44px 12px 12px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    backgroundColor: '#FFFFFF',
    fontFamily: "'Inter', sans-serif",

    '& .MuiInputBase-input': {
      fontSize: '16px',

      '&::placeholder': {
        fontFamily: "'Inter', sans-serif",
        color: '#94A3B8',
        fontSize: '16px',
        opacity: 1, // Firefox requires this
      },
    },
  }));

const StyledSelect = styled(Select)(() => ({
  borderRadius: "100px",
  height: "22px",
  fontSize: "12px",
  "& .MuiSelect-select": {
        padding: "0 24px 0 0 !important",
    },
  "& .MuiSelect-icon": {
    borderRadius: "0 100px 100px 0",
    borderLeft: "1px solid #E2E8F0",
    height: "22px",
    right: 0,
    top: 0,
    backgroundColor: "#F1F5F9",
  },
  "& .MuiSelect-iconOpen": {
    borderLeft: 'none',
    borderRadius: '100px 0 0 100px',
    borderRight: '1px solid #E2E8F0',
  },
}));

const StyledCommentInput = styled(InputBase)(() => ({
  padding: "10px 12px",
  borderRadius: "8px",
  border: "1px solid #CBD5E1",
  backgroundColor: "#FFFFFF",
  fontFamily: "'Inter', sans-serif",
  width: "100%",

  "& .MuiInputBase-input": {
    fontSize: "16px",
    color: "#0F172A !important",
    '-webkit-text-fill-color': '#0F172A !important',

    "&::placeholder": {
      fontFamily: "'Inter', sans-serif",
      color: "#94A3B8",
      fontSize: "16px",
      opacity: 1, // Firefox requires this
    },
  },
}));

const webStyle = {
  tenantsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  tenantsTableHeader: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    margin: "20px 0px",
  },
  searchContainer: {
    display: "flex",
    flexDirection: "row",
    width: "340px",
  },
  actionContainer: {
    display: "flex",
    flexDirection: "row",
  },
  tenantsTable: {
    border: "1px solid #CBD5E1",
    borderRadius: "12px",
    backgroundColor: '#FFFFFF',
  },
  tenantsTableHead: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 12px",
    backgroundColor: "#F8FAFC",
    borderRadius: "12px 12px 0 0",
  },
  tenantsTableBody: {
    display: "flex",
    flexDirection: "row",
    padding: "0px 12px",
    borderTop: "1px solid #CBD5E1",
    backgroundColor: "#FFFFFF",
    position: 'relative',
    "&:last-of-type": {
      borderRadius: "0 0 12px 12px",
    },
  },
  fieldControl: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    marginTop: "10px",
    marginBottom: "10px",
    position: "relative",
  },
  inputElemnt: {
    height: "44px",
    padding: "10px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  searchElement: {
    padding: "10px 40px 10px 10px",
  },
  searchIcon: {
    width: "24px",
    height: "24px",
    position: "absolute" as "absolute",
    top: 10,
    right: 16,
  },
  btnIcon: {
    width: "24px",
    height: "24px",
  },
  commentButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#1A7BA4",
    borderRadius: "7px",
    backgroundColor: "#F8FAFC",
    border: "1px solid #1A7BA4",
    display: "flex",
    height: "44px",
    alignItems: "center",
    margin: "10px 0 0",
    padding: "0 16px",
  },
  generateReportButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "22px",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "7px",
    backgroundColor: "#1A7BA4",
    display: "flex",
    height: "44px",
    alignItems: "center",
    margin: "10px 0 0 12px",
    padding: "0 16px",
  },
  headerTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    textTransform: "uppercase" as const,
    color: "#64748B",
    width: "100%",
    margin: "13px 4px",
  },
  tenantItem: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
    width: "100%",
    margin: "9px 4px",
    alignItems: "center",
    display: "flex",
    wordBreak: "break-word",
  },
  buttonText: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "24px",
  },
  selectProRateTenant: {
    fontFamily: "'Inter', sans-serif",
    height: "32px",
    fontSize: "14px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    width: "100%",
    fontWeight: 700,
    "& .MuiSelect-select": {
      fontFamily: "'Inter', sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
  },
  selectRateText: {
    fontFamily: "'Inter', sans-serif",
    padding: '2px 8px',
  },
  selectTenant: {
    fontFamily: "'Inter', sans-serif",
    height: "44px",
    fontSize: "16px",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
    minWidth: "220px",
    "& .MuiSelect-select": {
      fontFamily: "'Inter', sans-serif",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1",
    },
  },
  selectTenantOption: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    color: "#0F172A",
    padding: "8px 12px",
    margin: "0 8px 12px",
    borderRadius: "8px",
    "&:last-of-type": {
      margin: "0 8px",
    },
    "&.Mui-selected": {
      backgroundColor: "#E8FBFF",
      color: "#1A7BA4",
      fontWeight: "600",
    },
  },
  cancelButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "10px",
    height: "44px",
    borderRadius: "8px",
    border: "1px solid #1A7BA4",
    background: "#FFFFFF",
    color: "#1A7BA4",
    padding: "0px 20px",
  },
  submitButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "10px",
    height: "44px",
    borderRadius: "8px",
    border: "none",
    background: "#1A7BA4",
    color: "#FFFFFF",
    padding: "0px 20px",
  },
  dialogTitleText: {
    fontWeight: "700",
    fontSize: "24px",
    fontFamily: "'Inter', sans-serif",
  },
  tenantsDataTableHead: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: "0px 10px",
  },
  tenantsDataTableBody: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    fontWeight: 700,
  },
  tenantConsumption: {
    display: "flex",
    width: "100%",
  },
  tenantConsumptionText: {
    flex: "1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "22px",
    color: "#0F172A",
  },
  tenantConsumptionHeaderText: {
    flex: "1",
    fontFamily: "'Inter', sans-serif",
    fontSize: "12px",
    fontWeight: 700,
    lineHeight: "18px",
    color: "#64748B",
  },
  tenantConsumptionHeader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    flexDirection: "column",
  },
  addNewProRateButton: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "24px",
    marginTop: "8px",
    minHeight: "44px",
    borderRadius: "8px",
    border: "none",
    background: "inherit",
    color: "#1A7BA4",
    flexDirection: "row" as const,
    display: "flex",
    alignItems: "center",
    flex: 1,
    justifyContent: "center",
  },
  typographyTitleText1: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "22px",
  },
  proRateTitle: {
    fontFamily: "'Inter', sans-serif",
    fontSize: "16px",
    fontWeight: 500,
    color: "#334155",
    margin: "8px 0",
  },
  proRateTableRow: {
    display: "flex",
    padding: "12px",
    borderBottom: "1px solid #CBD5E1",
    position: 'relative',
    "&:last-of-type": {
      borderBottom: "none",
    },
  },
  filterWrapper: {
    display: "flex",
    flexDirection: "row",
    gap: "16px",
  },
  tenantSelectContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    marginTop: "-11px",
  },
  tenantSelectLabel: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    color: "#334155",
  },
  estimatedReadingWrapper: {
    display: "flex",
    fontSize: "14px",
    marginTop: "16px",
  },
  asterix: {
    color: "#DC2626",
  },
  estimatedReadingText: {
    color: "#0F172A",
  },
  tenantConsumptionHeaderTextTitle: {
    textAlign: "center",
    transform: "translateX(-12.5%)",
  },
  buttonsWrapper: {
    display: "flex",
    gap: "8px",
    marginTop: "24px",
    justifyContent: "flex-end",
  },
  commentLabel: {
    fontSize: "14px",
    fontWeight: 700,
    fontFamily: "'Inter', sans-serif",
    color: "#334155",
  },
  commentWrapper: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
  },
  removeItemButton: {
    position: 'absolute',
    left: -41,
    top: 12,
    width: '32px',
    height: '32px',
    backgroundColor: '#FEF2F2',
    borderRadius: '100px',
  },
  removeItemIcon: {
    color: '#DC2626',
    width: '24px',
    height: '24px',
  },
  meterCommentButton: (visible: boolean): any => ({
    position: 'relative',
    height: '28px',
    width: '28px',
    backgroundColor: '#E8F9FC',
    borderRadius: '50%',
    visibility: visible ? undefined : 'hidden',
  }),
  commentIcon: {
    height: '20px',
    width: '20px',
    color: '#1A7BA4',
  },
  commentBadge: {
    position: 'absolute',
    top: 0,
    right: 0,
    borderRadius: '100%',
    backgroundColor: '#DC2626',
    width: '8px',
    height: '8px',
  }
};
