import React, { useState } from "react";
import { IconButton, Box } from "@mui/material";
import { styled } from '@mui/material/styles';
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import UndoRoundedIcon from "@mui/icons-material/UndoRounded";

type CustomerEditableTextFieldProps = {
  initialValue: string;
  onChange: (value: string) => void;

  valueClass?: any;
  isMoneyValue?: boolean;
  isPercentageValue?: boolean;
  allowNumericOnly?: boolean;
  allowWholeNumericOnly?: boolean;
  noRevert?: boolean;
  emptyValue?: string;
};

export const CustomerEditableTextField = ({
  initialValue,
  onChange,
  isMoneyValue,
  isPercentageValue,
  allowNumericOnly = true,
  allowWholeNumericOnly,
  valueClass,
  noRevert,
  emptyValue,
}: CustomerEditableTextFieldProps) => {
  const [savedInitialValue] = useState(initialValue);
  const [value, setValue] = useState(initialValue);
  const [previousValue, setPreviousValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleUndo = () => {
    setValue(savedInitialValue);
    setPreviousValue(savedInitialValue);
    setHasChanged(false);
    onChange(savedInitialValue);
  };

  const handleAccept = () => {
    let newValue = (allowNumericOnly || allowWholeNumericOnly) && value !== '' ? Number(value).toString() : value; // Converts cases like '0001' to a valid number '1'
    newValue !== value && setValue(newValue);
    setPreviousValue(newValue);
    setIsEditing(false);
    setHasChanged(true);
    onChange(newValue);
  };

  const handleCancel = () => {
    setValue(previousValue);
    setIsEditing(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (allowWholeNumericOnly) {
      /^\d*$/.test(inputValue) && setValue(inputValue);
      
      return;
    }

    // Regex to allow numbers and a single decimal point
    if (!allowNumericOnly || /^\d*\.?\d*$/.test(inputValue)) {
      setValue(inputValue);
    }
  };

  const getTextValue = () => {
    if (value === '' && emptyValue) {
      return emptyValue;
    }

    if (isPercentageValue) {
      return `${value}%`;
    }

    if (isMoneyValue) {
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(Number(value));
    }

    return value;
  };

  const handleInputOnKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAccept();

      return;
    }

    if (event.key === 'Escape') {
      handleCancel();
    }
  }

  return (
    <Box sx={webStyle.wrapper}>
      {isEditing ? (
        <Box sx={webStyle.textFieldWrapper}>
          <StyledInput
            type="text"
            value={value}
            onChange={handleChange}
            onKeyDown={handleInputOnKeyDown}
            data-test-id="input"
            autoFocus
          />
          <Box sx={webStyle.textFieldButtonsWrapper}>
            <IconButton
              onClick={handleAccept}
              sx={webStyle.textFieldAcceptButtonIcon}
              data-test-id="acceptButton"
            >
              <CheckRoundedIcon sx={webStyle.textFieldAcceptIcon} />
            </IconButton>
            <IconButton
              onClick={handleCancel}
              sx={webStyle.textFieldCancelButtonIcon}
              data-test-id="cancelButton"
            >
              <CloseRoundedIcon sx={webStyle.textFieldCancelIcon} />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Box sx={webStyle.textWrapper}>
          <Box sx={{...webStyle.valueText, ...valueClass}} data-test-id="displayValue">{getTextValue()}</Box>
          <Box sx={webStyle.textIconWrapper}>
            <IconButton onClick={handleEdit} sx={webStyle.textEditButtonIcon} data-test-id="editButton">
              <EditRoundedIcon sx={webStyle.textEditIcon} />
            </IconButton>
            {hasChanged && !noRevert && (
              <IconButton onClick={handleUndo} sx={webStyle.textUndoButtonIcon} data-test-id="undoButton">
                <UndoRoundedIcon sx={webStyle.textUndoIcon} />
              </IconButton>
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
};

const StyledInput = styled('input')(() => ({
  padding: "0 50px 0 0",
  height: "22px",
  border: "1px solid #E2E8F0",
  borderRadius: "4px",
  width: "100%",
  outline: 'none',
  '&:hover, &:focus': {
    outline: 'none',
  },
}));

const webStyle = {
  wrapper: {
    position: "relative",
    width: "100%",
  },
  textFieldWrapper: {
    position: "relative",
    margin: "3px 0",
  },
  textFieldButtonsWrapper: {
    position: "absolute",
    right: 0,
    top: 0,
    display: "flex",
  },
  textFieldAcceptButtonIcon: {
    height: "22px",
    width: "22px",
    backgroundColor: "#D1FAE5",
    borderRadius: 0,
    borderTop: "1px solid #E2E8F0",
    borderBottom: "1px solid #E2E8F0",
  },
  textFieldCancelButtonIcon: {
    height: "22px",
    width: "22px",
    backgroundColor: "#FEE2E2",
    color: "#DC2626",
    borderRadius: "0 4px 4px 0",
    borderTop: "1px solid #E2E8F0",
    borderBottom: "1px solid #E2E8F0",
    borderRight: "1px solid #E2E8F0",
  },
  textFieldAcceptIcon: {
    width: "16px",
    height: "16px",
    color: "#059669",
  },
  textFieldCancelIcon: {
    width: "16px",
    height: "16px",
    color: "#DC2626",
  },
  textWrapper: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: "8px",
  },
  valueText: {
    fontSize: "14px",
    fontFamily: "'Inter', sans-serif",
  },
  textIconWrapper: {
    display: "flex",
    gap: "8px",
  },
  textEditButtonIcon: {
    height: "28px",
    width: "28px",
    backgroundColor: "#E8F9FC",
    borderRadius: "100px",
  },
  textUndoButtonIcon: {
    height: "28px",
    width: "28px",
    backgroundColor: "#F1F5F9",
    borderRadius: "100px",
  },
  textEditIcon: {
    width: "20px",
    height: "20px",
    color: "#1A7BA4",
  },
  textUndoIcon: {
    width: "20px",
    height: "20px",
    color: "#475569",
  },
};
